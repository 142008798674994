'use client';

import { Investors, Video } from '@/components/shared';

import {
  Animation,
  Header,
  Mission,
  GridValues,
  Awards,
  News,
  JoinUs,
  OurCulture,
  Press,
  HeaderBanner,
} from '@/components/company/careers';
import { Layout } from '@/components/core';

function PageContent({ bannerInfo, content, departments, spontaneousApplicationForm }) {
  const {
    headerBanner,
    joinUs,
    investors,
    mission,
    values,
    header,
    awards,
    culture,
    press,
    news,
    video,
  } = content;

  return (
    <Layout bannerInfo={bannerInfo}>
      <Animation>
        {headerBanner && <HeaderBanner content={headerBanner} />}
        <Header content={header} />
        {mission && <Mission content={mission} />}
      </Animation>
      {investors && (
        <Investors
          titleSize="3xl"
          content={investors}
          gradient={'linear-gradient(180deg, #f5f2f0 0%, #fff 100%)'}
        />
      )}
      {values && <GridValues content={values} />}
      {awards && <Awards content={awards} />}
      {video && <Video content={video} withBgColor withCircleBackground />}
      {press && <Press content={press} />}
      {news && <News content={news} />}
      {culture && <OurCulture content={culture} />}
      {departments?.length > 0 && (
        <JoinUs
          content={joinUs}
          departments={departments}
          spontaneousApplicationForm={spontaneousApplicationForm}
        />
      )}
    </Layout>
  );
}

export default PageContent;
